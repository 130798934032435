import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import DatePicker from 'react-mobile-datepicker';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { sendForgotEmail } from 'src/services/authService';
import { ROUTES_AUTHEN } from 'src/constants';
import { settingSelector } from 'src/slices/settings';
import LoaderLayer from 'src/components/LoaderLayer';
import { authSelector } from 'src/slices/auth';

const useStyles = makeStyles((theme) => ({
  resetLabel: {
    width: '200px',
    fontWeight: 'bold',
    lineHeight: 2,
    [theme.breakpoints.down(700)]: {
      width: '55%'
    }
  },
  labelBirthday: {
    fontWeight: 'bold',
    lineHeight: 2,
    width: '9rem',
    [theme.breakpoints.down(700)]: {
      width: '55%'
    }
  },
  resetSubLabel: {
    width: '8%',
    fontWeight: 'bold',
    lineHeight: 2,
    marginLeft: 4
  },
  wrapTextField: {
    width: '21%',
    [theme.breakpoints.down(700)]: {
      width: '18%'
    }
  },
  resetButton: {
    padding: '12px 140px',
    color: '#fff !important',
    borderRadius: 0,
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      padding: '6px 0',
      width: '80%'
    }
  },
  helperText: {
    '& p': {
      color: 'red'
    }
  },
  inputYear: {
    width: '34%'
  },
  wrapDatePicker: {
    width: '100%',
    '& .datepicker': {
      position: 'unset',
      width: '24rem',
      marginTop: '1rem'
    }
  },
  dateSelect: {
    border: 'none',
    padding: '0.5rem 0 0.5rem 0.3rem',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    borderRadius: 0,
    marginRight: 7,
    fontSize: 16,
    '& option:first-child': {
      display: 'none'
    }
  },
  yearSelect: {
    width: '5rem'
  },
  daySelect: {
    width: '3.5rem'
  }
}));

function LoginForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const settings = useSelector(settingSelector);
  const { isFetching } = useSelector(authSelector);

  const [hover, setHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [birthday, setBirthday] = useState(
    moment(new Date()).format('YYYY/MM/DD')
  );

  const [date, setDate] = useState({
    year: '',
    month: '',
    day: ''
  });

  const handleSelectOnMobile = (selectDate) => {
    let formattedDate = moment(selectDate).format('YYYY/MM/DD');
    setBirthday(formattedDate);
    document.getElementById('root').style.overflow = 'scroll';
    setIsOpen(false);
  };

  const dateConfig = {
    year: {
      format: 'YYYY',
      caption: '年',
      step: 1
    },
    month: {
      format: 'MM',
      caption: '月',
      step: 1
    },
    date: {
      format: 'DD',
      caption: '日',
      step: 1
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          email: ''
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('有効な電子メールアドレスでなければなりません')
            .max(255)
            .required('メールアドレス必須')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const { payload } = await dispatch(
              sendForgotEmail({
                email: values.email,
                birthday:
                  date.year !== '' && window.innerWidth > 900
                    ? `${date.year}-${parseInt(date.month) + 1}-${date.day}`
                    : moment(birthday).format('YYYY-MM-DD')
              })
            );

            if (payload?.data?.msg === 'Success') {
              navigate(ROUTES_AUTHEN.FORGOT_PASSWORD_OTP);
            } else {
              alert(payload?.msg);
            }

            onSubmitSuccess();
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Box display="flex" mb={5}>
              <Typography className={clsx(classes.resetLabel)}>
                メールアドレス
              </Typography>
              <TextField
                name="email"
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                type="email"
                variant="standard"
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: 'black',
                    height: 38,
                    backgroundColor: '#FFFFFF',
                    paddingLeft: 10
                  }
                }}
              />
            </Box>

            <Box display="flex">
              <Typography className={clsx(classes.labelBirthday)}>
                生年月日
              </Typography>
              {window.innerWidth <= 900 ? (
                <TextField
                  name="birthday"
                  value={birthday}
                  onFocus={() => {
                    setIsOpen(true);
                    document.getElementById('root').style.overflow = 'hidden';
                  }}
                  variant="standard"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: {
                      color: 'black',
                      height: 38,
                      backgroundColor: '#FFFFFF',
                      paddingLeft: 10
                    }
                  }}
                />
              ) : (
                <>
                  <YearPicker
                    reverse // default is ASCENDING
                    required
                    value={date.year} // mandatory
                    onChange={(year) => {
                      // mandatory
                      setDate((prev) => ({ ...prev, year }));
                    }}
                    id={'year'}
                    classes={clsx(classes.dateSelect, classes.yearSelect)}
                  />
                  <Typography className={clsx(classes.resetSubLabel)}>
                    年
                  </Typography>

                  <MonthPicker
                    numeric // to get months as numbers
                    endYearGiven // mandatory if end={} is given in YearPicker
                    year={date.year} // mandatory
                    value={date.month} // mandatory
                    required
                    onChange={(month) => {
                      // mandatory
                      setDate((prev) => ({ ...prev, month }));
                    }}
                    id={'month'}
                    classes={clsx(classes.dateSelect, classes.daySelect)}
                  />
                  <Typography className={clsx(classes.resetSubLabel)}>
                    月
                  </Typography>

                  <DayPicker
                    year={date.year} // mandatory
                    month={date.month} // mandatory
                    required
                    endYearGiven // mandatory if end={} is given in YearPicker
                    value={date.day} // mandatory
                    onChange={(day) => {
                      // mandatory
                      setDate((prev) => ({ ...prev, day }));
                    }}
                    id={'day'}
                    classes={clsx(classes.dateSelect, classes.daySelect)}
                  />
                  <Typography className={clsx(classes.resetSubLabel)}>
                    日
                  </Typography>
                </>
              )}
            </Box>

            <Box mt={10} display="flex" sx={{ justifyContent: 'center' }}>
              <Button
                type="submit"
                disabled={isSubmitting}
                className={clsx(classes.resetButton)}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                style={{
                  backgroundColor: hover
                    ? settings?.settings?.theme_color1 + 'cc'
                    : settings?.settings?.theme_color1
                }}
              >
                送信する
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <DatePicker
        theme="ios"
        isOpen={isOpen}
        min={new Date('1900/01/01')}
        max={new Date()}
        dateConfig={dateConfig}
        onSelect={(e) => handleSelectOnMobile(e)}
        onCancel={() => {
          setIsOpen(false);
          document.getElementById('root').style.overflow = 'scroll';
        }}
        value={new Date(birthday)}
        confirmText="完了"
        cancelText="取消"
      />
      <LoaderLayer loading={isFetching} />
    </>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};

export default LoginForm;
