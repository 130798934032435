import clsx from 'clsx';
import _ from 'lodash';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router';
import postal_code from 'japan-postal-code';
import DatePicker from 'react-mobile-datepicker';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button,
  TextField,
  styled,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';

import { authSelector } from 'src/slices/auth';
import { ROUTES_AUTHEN } from 'src/constants';
import {
  checkNameTalent,
  getDomainTalent,
  getLoginToken
} from 'src/utils/auth';
import { updateProfile } from 'src/services/authService';
import LoaderLayer from 'src/components/LoaderLayer';
import PaymentDialog from 'src/components/PaymentDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5rem',
    [theme.breakpoints.down(700)]: {
      padding: '3rem 0 2rem',
      '& .PrivateSwitchBase-root-125': {
        padding: 2
      }
    }
  },
  fieldError: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    }
  },
  itemCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapTitleSP: {
    margin: '2rem 0',
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2rem'
    }
  },
  pageTitle: {
    color: '#000000',
    fontSize: '1.4rem',
    fontWeight: '600',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem',
      padding: '0 1.5rem 0 0'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5rem 0',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      margin: '2rem 0'
    }
  },
  buttonBack: {
    backgroundColor: '#A7A7A7',
    padding: '11px 0',
    width: 260,
    color: '#ffffff !important',
    borderRadius: 0,
    marginRight: '2rem',
    '&:hover': {
      backgroundColor: '#5b5b5b'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 0',
      width: '100%',
      marginBottom: '1rem'
    }
  },
  buttonEdit: {
    backgroundColor: 'black',
    color: '#ffffff !important',
    borderRadius: 0,
    width: 260,
    padding: '11px 0',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 0',
      width: '100%'
    }
  },
  buttonSmall: {
    padding: '0.2rem 2rem',
    borderRadius: 0,
    height: 'fit-content',
    color: 'white'
  },
  wrapButtonSmallSP: {
    [theme.breakpoints.down(700)]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end'
    }
  },
  buttonSmallSP: {
    marginBottom: '0.2rem'
  },
  wrapTextConfirm: {
    margin: '2rem 0 1rem'
  },
  textConfirm: {
    fontWeight: '600'
  },
  containerProfile: {
    backgroundColor: '#ffffff',
    padding: '2rem',
    [theme.breakpoints.down(700)]: {
      padding: '0.5rem'
    }
  },
  containerProfileDetail: {
    display: 'flex'
  },
  profileLeft: {
    width: '20%',
    backgroundColor: '#C5C5C5',
    textAlign: 'center',
    borderRight: '1px solid #707070',

    borderBottom: '1px dashed #AFAFAF',
    '& p': {
      padding: '0.5rem 0',
      height: '100%',
      [theme.breakpoints.down(700)]: {
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '37%'
    }
  },
  profileRight: {
    flexGrow: 1,
    width: '80%',
    borderBottom: '1px dashed #AFAFAF',
    '& p': {
      padding: '0.5rem 0 0 1rem',
      height: '100%',
      [theme.breakpoints.down(700)]: {
        padding: '0.5rem 0 0.5rem 0.8rem',
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '63%'
    }
  },
  profileRightContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  profileRightSP: {
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start'
    }
  },
  radio: {
    fontSize: '1.2rem',
    color: '#333333',
    '&$checked': {
      color: '#333333'
    }
  },
  checked: {},
  dateSelect: {
    padding: '4px 10px',
    borderRadius: 0,
    borderColor: '#707070',
    outline: 'none',
    '& option:first-child': {
      display: 'none'
    }
  },
  labelBirthday: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem 0 0.5rem !important'
  },
  labelSex: {
    fontSize: 16,
    [theme.breakpoints.down(768)]: {
      fontSize: 14
    }
  },
  wrapRadioGroup: {
    [theme.breakpoints.down(768)]: {
      marginTop: 4
    }
  }
}));

const CssTextField = styled(TextField)({
  marginTop: 4,
  paddingLeft: '0.5rem',
  '& input': {
    color: 'black',
    padding: '6.5px 14px'
  },
  '& fieldset': {
    borderRadius: 0
  },
  '& label.Mui-focused': {
    color: 'black'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#707070'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#707070'
    },
    '&:hover fieldset': {
      borderColor: '#707070'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#707070'
    }
  }
});

function UpdateProfile({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector(authSelector);
  const { state } = useLocation();
  const { profile } = useSelector(authSelector);
  const { nameLower } = checkNameTalent();

  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState('');
  const [memberProfile, setMemberProfile] = useState({});
  const [date, setDate] = useState({
    year: moment(profile?.birthday).year(),
    month: moment(profile?.birthday).month(),
    day: moment(profile?.birthday).format('D')
  });

  const formik = useFormik({
    initialValues: {
      first_name: profile?.first_name,
      family_name: profile?.family_name,
      first_furigana: profile?.first_furigana,
      family_furigana: profile?.family_furigana,
      nickname: memberProfile?.nickname || '',
      birthday: moment(profile?.birthday).format('YYYY/MM/DD'),
      post_code: profile?.post_code,
      address1: profile?.address1,
      address2: profile?.address2,
      address3: profile?.address3,
      tel: profile?.tel,
      gender: profile?.gender?.toString(),
      payment_type: '1'
    },
    validationSchema: Yup.object().shape({
      family_name: Yup.string().max(45, '全角45文字以内').required('必須'),
      first_name: Yup.string().max(45, '全角45文字以内').required('必須'),
      family_furigana: Yup.string()
        .matches(/^[ァ-ヶー　]*$/, 'カタカナの全角文字で入力してください')
        .max(45, '全角45文字以内')
        .required('必須'),
      first_furigana: Yup.string()
        .matches(/^[ァ-ヶー　]*$/, 'カタカナの全角文字で入力してください')
        .max(45, '全角45文字以内')
        .required('必須'),
      nickname: Yup.string().max(45, '全角45文字以内').required('必須'),
      post_code: Yup.string()
        .matches(/^\d+$/, '半角数字を入力してください')
        .required('必須'),
      tel: Yup.string()
        .matches(/^[\d]{0,15}$/, 'ハイフンなしの半角数字で入力してください')
        .required('必須'),
      address1: Yup.string().required('必須'),
      address2: Yup.string().required('必須'),
      address3: Yup.string().required('必須')
    }),
    onSubmit: async (values) => {
      const data = {
        family_name: values.family_name,
        first_name: values.first_name,
        family_furigana: values.family_furigana,
        first_furigana: values.first_furigana,
        nickname: values.nickname,
        birthday:
          date.year !== '' && window.innerWidth > 900
            ? `${date.year}-${parseInt(date.month) + 1}-${date.day}`
            : moment(values.birthday).format('YYYY-MM-DD'),
        post_code: values.post_code,
        address1: values.address1,
        address2: values.address2,
        address3: values.address3,
        tel: values.tel,
        gender: values.gender
      };

      const { payload } = await dispatch(updateProfile(data));
      if (payload?.msg === 'Success') {
        window.scrollTo(0, 0);
        switch (values?.payment_type) {
          case '1':
            return navigate(ROUTES_AUTHEN.PAYMENT_CREDIT_INFO);
          case '2':
            return navigate(ROUTES_AUTHEN.PAYMENT_ATM);
          case '3':
            return navigate(ROUTES_AUTHEN.PAYMENT_STORE);
          case '4':
            return navigate(ROUTES_AUTHEN.PAYMENT_BANKING);
          default:
            break;
        }
      } else {
        if (payload?.errors?.nickname) {
          setTitleDialog(payload?.errors?.nickname[0]);
        } else {
          setTitleDialog(payload?.msg);
        }
        setOpenDialog(true);
      }
    }
  });

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    setMemberProfile(myProfile);
    formik.setFieldValue('nickname', myProfile?.nickname || '');
    formik.setFieldValue(
      'payment_type',
      state?.paymentType || myProfile?.payment_type
    );
  }, []);

  const getPostCode = (postCode) => {
    postal_code.get(postCode, (address) => {
      formik.setFieldValue('address1', address.prefecture);
      formik.setFieldValue(
        'address2',
        address.city + address.area + address.street
      );
    });
  };

  const handleSelectOnMobile = (selectDate) => {
    let formattedDate = moment(selectDate).format('YYYY/MM/DD');
    formik.setFieldValue('birthday', formattedDate);
    document.getElementById('root').style.overflow = 'scroll';
    setIsOpen(false);
  };

  const dateConfig = {
    year: {
      format: 'YYYY',
      caption: '年',
      step: 1
    },
    month: {
      format: 'MM',
      caption: '月',
      step: 1
    },
    date: {
      format: 'DD',
      caption: '日',
      step: 1
    }
  };

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box className={clsx(classes.wrapTitleSP)}>
            <Typography className={clsx(classes.pageTitle)}>
              MYPAGE -マイページ-
            </Typography>
          </Box>

          <Box className={clsx(classes.itemCenter, classes.wrapTextConfirm)}>
            <Typography variant="h6" className={clsx(classes.textConfirm)}>
              会員情報確認
            </Typography>
          </Box>

          <form onSubmit={formik.handleSubmit}>
            <Box className={clsx(classes.containerProfile)}>
              <Box>
                {/* Name */}
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>お名前</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <Box display="flex">
                      <Box style={{ width: '100%' }}>
                        <CssTextField
                          name="family_name"
                          value={formik.values.family_name}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.family_name &&
                              formik.errors.family_name
                          )}
                          className={clsx(
                            Boolean(formik.errors.family_name) &&
                              classes.fieldError
                          )}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />

                        {formik.errors.family_name && (
                          <Typography
                            style={{ color: 'red', fontSize: '0.8rem' }}
                          >
                            {formik.errors.family_name}
                          </Typography>
                        )}
                      </Box>

                      <Box style={{ width: '100%' }}>
                        <CssTextField
                          name="first_name"
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.first_name &&
                              formik.errors.first_name
                          )}
                          className={clsx(
                            Boolean(formik.errors.first_name) &&
                              classes.fieldError
                          )}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />

                        {formik.errors.first_name && (
                          <Typography
                            style={{ color: 'red', fontSize: '0.8rem' }}
                          >
                            {formik.errors.first_name}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* Furigana */}
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>フリガナ</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <Box display="flex">
                      <Box style={{ width: '100%' }}>
                        <CssTextField
                          name="family_furigana"
                          value={formik.values.family_furigana}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.family_furigana &&
                              formik.errors.family_furigana
                          )}
                          className={clsx(
                            Boolean(formik.errors.family_furigana) &&
                              classes.fieldError
                          )}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />

                        {formik.errors.family_furigana && (
                          <Typography
                            style={{ color: 'red', fontSize: '0.8rem' }}
                          >
                            {formik.errors.family_furigana}
                          </Typography>
                        )}
                      </Box>

                      <Box style={{ width: '100%' }}>
                        <CssTextField
                          name="first_furigana"
                          value={formik.values.first_furigana}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.first_furigana &&
                              formik.errors.first_furigana
                          )}
                          className={clsx(
                            Boolean(formik.errors.first_furigana) &&
                              classes.fieldError
                          )}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />

                        {formik.errors.first_furigana && (
                          <Typography
                            style={{ color: 'red', fontSize: '0.8rem' }}
                          >
                            {formik.errors.first_furigana}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* Nick name */}
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>ニックネーム</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <CssTextField
                      name="nickname"
                      value={formik.values.nickname}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.nickname && formik.errors.nickname
                      )}
                      className={clsx(
                        formik.values.nickname === '' && classes.fieldError,
                        formik.values.nickname.length > 45 && classes.fieldError
                      )}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    {formik.values.nickname === '' && (
                      <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
                        {formik.errors.nickname}
                      </Typography>
                    )}

                    {formik.values.nickname.length > 45 && (
                      <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
                        {formik.errors.nickname}
                      </Typography>
                    )}
                  </Box>
                </Box>

                {/* Birthday */}
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>生年月日</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    {window.innerWidth <= 900 ? (
                      <CssTextField
                        name="birthday"
                        fullWidth
                        value={formik.values.birthday}
                        onFocus={() => {
                          setIsOpen(true);
                          document.getElementById('root').style.overflow =
                            'hidden';
                        }}
                        size="small"
                        variant="outlined"
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    ) : (
                      <Box
                        display={'flex'}
                        alignItems="center"
                        style={{ height: '100%', marginLeft: 8 }}
                      >
                        <YearPicker
                          reverse
                          required
                          end={new Date().getFullYear() - 1}
                          value={date.year}
                          onChange={(year) => {
                            setDate((prev) => ({ ...prev, year }));
                          }}
                          id={'year'}
                          classes={clsx(classes.dateSelect, classes.yearSelect)}
                        />
                        <Typography className={clsx(classes.labelBirthday)}>
                          年
                        </Typography>
                        <MonthPicker
                          numeric
                          endYearGiven
                          year={date.year}
                          value={date.month}
                          required
                          onChange={(month) => {
                            setDate((prev) => ({ ...prev, month }));
                          }}
                          id={'month'}
                          classes={clsx(classes.dateSelect, classes.daySelect)}
                        />
                        <Typography className={clsx(classes.labelBirthday)}>
                          月
                        </Typography>
                        <DayPicker
                          year={date.year}
                          month={date.month}
                          required
                          endYearGiven
                          value={date.day}
                          onChange={(day) => {
                            setDate((prev) => ({ ...prev, day }));
                          }}
                          id={'day'}
                          classes={clsx(classes.dateSelect, classes.daySelect)}
                        />
                        <Typography className={clsx(classes.labelBirthday)}>
                          日
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>

                {/* Post code */}
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>郵便番号/住所</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <Box>
                      <Typography>郵便番号</Typography>
                      <CssTextField
                        name="post_code"
                        value={formik.values.post_code}
                        onChange={(event) => {
                          formik.handleChange('post_code')(event);
                          getPostCode(event.target.value);
                        }}
                        error={Boolean(
                          formik.touched.post_code && formik.errors.post_code
                        )}
                        className={clsx(
                          Boolean(formik.errors.post_code) && classes.fieldError
                        )}
                        variant="outlined"
                        size="small"
                      />
                      {formik.errors.post_code && (
                        <Typography
                          style={{ color: 'red', fontSize: '0.8rem' }}
                        >
                          {formik.errors.post_code}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography>都道府県</Typography>
                      <CssTextField
                        name="address1"
                        value={formik.values.address1}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.address1 && formik.errors.address1
                        )}
                        className={clsx(
                          Boolean(formik.errors.address1) && classes.fieldError
                        )}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      {formik.errors.address1 && (
                        <Typography
                          style={{ color: 'red', fontSize: '0.8rem' }}
                        >
                          {formik.errors.address1}
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      <Typography>市区町村</Typography>
                      <CssTextField
                        name="address2"
                        value={formik.values.address2}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.address2 && formik.errors.address2
                        )}
                        className={clsx(
                          Boolean(formik.errors.address2) && classes.fieldError
                        )}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      {formik.errors.address2 && (
                        <Typography
                          style={{ color: 'red', fontSize: '0.8rem' }}
                        >
                          {formik.errors.address2}
                        </Typography>
                      )}
                    </Box>
                    <Box mb={1}>
                      <Typography>番地・建物名</Typography>
                      <CssTextField
                        name="address3"
                        value={formik.values.address3}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.address3 && formik.errors.address3
                        )}
                        className={clsx(
                          Boolean(formik.errors.address3) && classes.fieldError
                        )}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      {formik.errors.address3 && (
                        <Typography
                          style={{ color: 'red', fontSize: '0.8rem' }}
                        >
                          {formik.errors.address3}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>

                {/* Phone */}
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>電話番号</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <CssTextField
                      name="tel"
                      value={formik.values.tel}
                      onChange={formik.handleChange}
                      error={Boolean(formik.touched.tel && formik.errors.tel)}
                      className={clsx(
                        Boolean(formik.errors.tel) && classes.fieldError
                      )}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    {formik.errors.tel && (
                      <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
                        {formik.errors.tel}
                      </Typography>
                    )}
                  </Box>
                </Box>

                {/* Email */}
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>メールアドレス</Typography>
                  </Box>

                  <Box
                    className={clsx(
                      classes.profileRight,
                      classes.profileRightContainer,
                      classes.profileRightSP
                    )}
                  >
                    <Typography>{profile?.email}</Typography>
                    <Box className={clsx(classes.wrapButtonSmallSP)}>
                      <Button
                        className={clsx(
                          classes.buttonSmall,
                          classes.buttonSmallSP
                        )}
                        style={{ backgroundColor: setting?.theme_color2 }}
                        onClick={() =>
                          navigate(ROUTES_AUTHEN.CHANGE_EMAIL, {
                            state: {
                              pathBack: ROUTES_AUTHEN.UPDATE_PROFILE
                            }
                          })
                        }
                      >
                        変更する
                      </Button>
                    </Box>
                  </Box>
                </Box>

                {/* Gender */}
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>性別</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <RadioGroup
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      style={{ marginLeft: '1rem' }}
                      className={clsx(classes.wrapRadioGroup)}
                      row
                    >
                      <FormControlLabel
                        value="2"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked
                            }}
                            size="small"
                          />
                        }
                        label={
                          <span className={clsx(classes.labelSex)}>女性</span>
                        }
                      />
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked
                            }}
                            size="small"
                          />
                        }
                        label={
                          <span className={clsx(classes.labelSex)}>男性</span>
                        }
                      />
                      <FormControlLabel
                        value="3"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked
                            }}
                            size="small"
                          />
                        }
                        label={
                          <span className={clsx(classes.labelSex)}>その他</span>
                        }
                      />
                    </RadioGroup>
                  </Box>
                </Box>

                {/* Payment type */}
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>支払い方法</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <RadioGroup
                      name="payment_type"
                      value={formik.values.payment_type}
                      onChange={formik.handleChange}
                      style={{ marginLeft: '1rem' }}
                      className={clsx(classes.wrapRadioGroup)}
                      row
                    >
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked
                            }}
                            size="small"
                          />
                        }
                        label={
                          <span className={clsx(classes.labelSex)}>
                            クレジットカード
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="2"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked
                            }}
                            size="small"
                          />
                        }
                        label={
                          <span className={clsx(classes.labelSex)}>
                            ATM決済
                          </span>
                        }
                      />
                      {nameLower !== 'kim-jiwon' && (
                        <FormControlLabel
                          value="3"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                              size="small"
                            />
                          }
                          label={
                            <span className={clsx(classes.labelSex)}>
                              コンビニ決済
                            </span>
                          }
                        />
                      )}

                      <FormControlLabel
                        value="4"
                        control={
                          <Radio
                            classes={{
                              root: classes.radio,
                              checked: classes.checked
                            }}
                            size="small"
                          />
                        }
                        label={
                          <span className={clsx(classes.labelSex)}>
                            銀行ネット決済
                          </span>
                        }
                      />
                    </RadioGroup>
                  </Box>
                </Box>

                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>パスワード</Typography>
                  </Box>

                  <Box
                    className={clsx(
                      classes.profileRight,
                      classes.profileRightContainer
                    )}
                  >
                    <Typography>*******</Typography>
                    <Button
                      className={clsx(classes.buttonSmall)}
                      style={{ backgroundColor: setting?.theme_color2 }}
                      onClick={() =>
                        navigate(ROUTES_AUTHEN.UPDATE_PASSWORD, {
                          state: {
                            pathBack: ROUTES_AUTHEN.UPDATE_PROFILE
                          }
                        })
                      }
                    >
                      変更する
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Typography
                style={{
                  marginTop: '10px',
                  fontSize: '0.8rem',
                  color: '#707070'
                }}
              >
                メールアドレスとパスワードは別画面での設定が必要ですので、個別に変更するボタンを押してご入力ください。
              </Typography>
            </Box>

            <Box className={clsx(classes.wrapButton)}>
              <Button
                onClick={() => navigate(-1)}
                className={clsx(classes.buttonBack)}
              >
                戻る
              </Button>
              <Button type="submit" className={clsx(classes.buttonEdit)}>
                情報を変更する
              </Button>
            </Box>
          </form>
        </Container>
      )}

      <LoaderLayer loading={auth?.isFetching} />
      <PaymentDialog
        open={openDialog}
        onClose={setOpenDialog}
        title={titleDialog}
      />

      <DatePicker
        theme="ios"
        isOpen={isOpen}
        dateConfig={dateConfig}
        min={new Date('1900/01/01')}
        max={new Date(`${moment().subtract(1, 'years').year()}/12/31`)}
        onSelect={(e) => handleSelectOnMobile(e)}
        onCancel={() => {
          setIsOpen(false);
          document.getElementById('root').style.overflow = 'scroll';
        }}
        value={new Date(formik.values.birthday)}
        confirmText="完了"
        cancelText="取消"
      />
    </div>
  );
}

export default UpdateProfile;
